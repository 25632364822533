import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexSeadweller = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexSeadweller {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Sea-Dweller/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Seadweller Watches for Sale'}
      canonical={'/fine-watches/rolex/'}
      description={
        'Grays & Sons are experts in buying and selling second hand Rolex Seadweller Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/filter/model/sea-dweller/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-seadweller-header.png"
                alt="Pre-Owned Certified Used Rolex Sea-Dweller Watches Header"
                aria-label="Used Rolex Sea-Dweller Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">
              ROLEX SEA-DWELLER: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES
            </h1>
            <h2>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX WATCHES</h2>
            <p>
              <b>Pre-Owned Rolex Sea-Dweller</b>
              <br />
              Rolex launched the Sea-Dweller in 1967 as a more robust version of the Submariner
              diving watch. Thanks to its helium escape valve and deeper water-resistance rating,
              the Rolex Sea-Dweller became the must-have saturation dive watch for the COMEX crew of
              professional deep-sea divers. Today, used Rolex Sea-Dweller watches are recognized as
              some of the best luxury watches in the pre-owned market. While Rolex used to make
              Sea-Dwellers exclusively in stainless steel with 40mm cases, the company recently
              redesigned the model to not only feature a larger 43mm case but also introduced a
              two-tone steel and yellow gold version.
              <br />
              <br />
              <b>Buying and Selling Used Rolex Submariner Watches</b>
              <br />
              As an independent Rolex dealer, Gray &amp; Sons benefits from price flexibility and
              inventory control. Our decision-makers are ready to make a deal on any item in our
              well-stocked used Rolex watch inventory, which is home to an assortment of secondhand
              Sea-Dweller watches. Any preowned Rolex Sea-Dweller for sale on our website is in
              stock and ready to ship right now. On the other hand, if you’re wondering, “where can
              I sell my used Rolex Sea-Dweller watch?” visit{' '}
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com </a>
              for the best cash offer in the market. If you're ready now to chat with a Rolex
              Sea-Dweller expert,
              <a href="/chat-with-rich">CLICK HERE </a>
              and you will be directed to our great specialists.
              <br />
              <br />
              Secondhand Rolex Sea-Dweller watch reference numbers:
              <ul>
                <li>Sea-Dweller 1665 “Double Red”</li>
                <li>Sea-Dweller 1665 “Great White”</li>
                <li>Sea-Dweller 16660</li>
                <li>Sea-Dweller 116660</li>
                <li>Sea-Dweller 126600, 43mm</li>
                <li>Sea-Dweller 126603, 43mm two-tone steel and gold</li>
              </ul>
              <br />
              <br />
              <b>
                Gray &amp; Sons is the Best Source for Buying and Selling Pre-Owned Rolex
                Sea-Dweller
              </b>
              According to the countless happy customers we’ve had the pleasure of serving over the
              last 40-plus years, Gray &amp; Sons, <a href="/">www.grayandsons.com </a> is the #1
              buyer and seller of pre-owned used Rolex Oyster Sea-Dweller watches. In addition to
              our leading e-commerce luxury watch website, Gray &amp; Sons is also a physical store,
              located adjacent to the prestigious Bal Harbour Shops in the Surfside/Miami area.
              <br />
              <br />
              We’re open six days a week, so feel free to drop in to browse our large selection of
              secondhand Rolex Sea-Dweller watches. Our team of watch experts can help you buy or
              sell a pre-owned used Rolex Sea-Dweller watch. What’s more, since Gray &amp; Sons also
              has an independent Rolex repair facility onsite, you can also bring your Sea-Dweller
              in for servicing by one of our skilled watchmakers.
              <br />
              <br />
              Gray &amp; Sons serves the Miami, Miami Beach, Coral Gables, Coconut Grove, South
              Beach, Bal Harbour, Surfside, Sunny Isles, Ventura, Fort Lauderdale, Pinecrest, and
              Brickle areas. We offver Certified Authentic and Certified Pre-Owned watches.
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex/filter/model/sea-dweller/">
                <button>SHOP ROLEX SEA-DWELLER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX SEA-DWELLER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexSeadweller
